import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../../../ui-treact/components/misc/Headings.js";
import { PrimaryButton } from "../../../../ui-treact/components/misc/Buttons.js";
// import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg"; 
// import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
// import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
// import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";
import QuotesL from '../svgs/QuotesL'
import QuotesR from '../svgs/QuotesR'
import ChevronRight from "../svgs/ChevronRight.js";
import ChevronLeft from "../svgs/ChevronLeft.js";
import Testi1 from 'src/images/testi1.jpeg'
import Testi2 from 'src/images/testi2.jpeg'

import TestiPro1 from 'src/images/testipro1.jpeg'
import TestiPro2 from 'src/images/testipro2.jpeg'

// const StyledDiv = styled.div``

const Container = tw.div`relative w-full bg-white`;
const Content = tw.div`max-w-screen-xl mx-auto py-8`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;

const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.text_on_left ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-800`;

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-800`;
const CustomerTitle = tw.p`font-medium text-secondary-800`;

const QuotesLeft = tw(QuotesL)`w-6 h-6 opacity-75 text-primary-500 inline-block mr-1 -mt-3`;
const QuotesRight = tw(QuotesR)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3`;

// const DecoratorBlob1 = tw(
//   StyledDiv
// )`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
// const DecoratorBlob2 = tw(
//   StyledDiv
// )`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Testimonials",
        heading: (
            <>
              Our Clients <span tw="text-primary-500">Love Us.</span>
            </>
        ),
        description: "Here are what some of our amazing customers are saying about our hotels & tours. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_on_left: true,
        testimonials: [
            {
              imageSrc:Testi1,
              profileImageSrc:TestiPro1,
              quote:
                "SickMarketing is very professional and knows how to take your business to the next level digitally.  I refer many clients to him and they are beyond pleased.",
              customerName: "Charlotte Hale",
              customerTitle: "CEO, Infinity Creatives Inc."
            },
            {
              imageSrc:Testi2,
              profileImageSrc:TestiPro2,
              quote:
                "One word, FABULOUS! Wait okay two words, Kind! Wait okay three words, professional! Wait okay four words, fast! Okay you get the idea, Ron is amazing just go ahead and hire him, you will not be sorry!",
              customerName: "Ali Raza",
              customerTitle: "Founder, Home Tech"
            }
          ],
       ...finalData
  } 

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo tw="text-center lg:hidden" sub_heading={content.sub_heading} heading={content.heading} description={content.description} />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>
                {content.testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeft />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRight />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer text_on_left={content.text_on_left}>
                <HeadingInfo tw="hidden lg:block" sub_heading={content.sub_heading} heading={content.heading} description={content.description} />
                <TestimonialTextSlider arrows={false} ref={setTextSliderRef} asNavFor={imageSliderRef} fade={true}>
                  {content.testimonials.map((testimonial, index) => (
                    <TestimonialText key={index}>
                      <QuoteContainer>
                        <Quote>
                          <QuotesLeft />
                          {testimonial.quote}
                          <QuotesRight />
                        </Quote>
                      </QuoteContainer>
                      <CustomerInfo>
                        <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />
                        <CustomerTextInfo>
                          <CustomerName>{testimonial.customerName}</CustomerName>
                          <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                        </CustomerTextInfo>
                      </CustomerInfo>
                    </TestimonialText>
                  ))}
                </TestimonialTextSlider>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};

const HeadingInfo = ({ sub_heading, heading, description, ...props }) => (
  <div {...props}>
    {sub_heading ? <Subheading>{sub_heading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
